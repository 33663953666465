import React from 'react';
import Layout from "../components/layout";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from "@material-ui/core";
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
    boxContenedor:{
        position:'relative',
        textAlign:"center",
        margin:"2rem",
    },
}));

function Default404(props) {
    const classes = useStyles();
    return (
        <Layout>
            <Box className={classes.boxContenedor} >
                <Typography component="p">
                    <b>UPS! Contenido no encontrado.</b>
                </Typography>
            </Box>
        </Layout>
    );
}

export default withWidth()(Default404);
